const appTranslations = {
    en: {
        companyName: 'Smartberry Games',

        'title/chess': 'Chess',
        'title/checkersEn': 'English Checkers',
        'title/checkersRu': 'Russian Checkers',
        'title/poddavki': 'Giveaway Checkers',
        'title/saper': 'Minesweeper Duel',
        'title/petsAndPaints': 'Pets and Paints Arrange Puzzle',
        'title/marketplace': 'Marketplace',
        'title/retroRacerTurbo': 'Retro Racer Turbo',

        play: 'Play on',

        'text/weDevelopAndPublish': 'We develop and publish smart web games',
        'text/toCooperateWriteTo': 'For cooperation, contact us by',
    },
    ru: {
        companyName: 'Смартбери Игры',

        'title/chess': 'Шахматы Ру',
        'title/checkersEn': 'Английские Шашки',
        'title/checkersRu': 'Шашки Ру',
        'title/poddavki': 'Поддавки Ру',
        'title/saper': 'Сапёр Дуэль',
        'title/petsAndPaints': 'Головоломка Звери и краски',
        'title/marketplace': 'Маркет',
        'title/retroRacerTurbo': 'Retro Racer Turbo',

        play: 'Играть',

        'text/weDevelopAndPublish': 'Разрабатываем и публикуем интеллектуальные онлайн игры',
        'text/toCooperateWriteTo': 'По вопросам сотрудничества пишите на',
    },
};

export default appTranslations;
