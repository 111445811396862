import { Image } from '@mantine/core';
import classNames from 'classnames';

import style from './ImageLink.module.scss';

const ImageLink = ({ src = '', url = '', mix, ...rest }) => {
    return (
        <a target="_blank" href={url} rel="noreferrer">
            <Image
                src={src}
                radius="md"
                className={classNames(style.imageLink, mix)}
                {...rest}
            />
        </a>
    );
};

export default ImageLink;
