import { Cards } from '../Cards/Cards';
import { Flex } from '@mantine/core';

const Main = () => {
    return (
        <Flex align="center" direction="column" gap={8} pt={32}>
            <Cards />
        </Flex>
    );
};
export default Main;
